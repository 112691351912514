.container {
    display: grid;
    grid-template-columns: 24.875rem 1fr 10rem;
    grid-template-areas:
      'title title count'
      'result result result';
    gap: 1.292rem;
    @media (max-width: 1500px) {
      grid-template-columns: 0 1fr 10rem;
      grid-template-areas:
      'title title title'
      'count count count'
      'result result result';
    }
  }
  
  .title {
    grid-area: title;
  }
  
  .filter {
    grid-area: filter;
  }
  
  .result {
    grid-area: result;
  }
  
  .count {
    grid-area: count;
  }
  