.container {
  border-width: 0 !important;
}

.control {
  background-color: white !important;
  border-width: 0.5px;
  border: 1px solid #0066FF1A !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.menu {
  padding: 0 !important;
  border-radius: 5px;
  margin-top: 1rem;
  color: #111136;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.menu > {
  div {
    padding: 0 !important;
    border-radius: 5px;
  }

  div > {
    div {
      min-height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.SelectTextLabel {
  font-family: 'Poppins' !important;
}
