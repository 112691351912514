@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.suggestion-list::-webkit-scrollbar {
  box-shadow: inset 0 0 5px #bdc5cd;
  border-radius: 10px;
  width: 5px;
  color: yellow;
}

.suggestion-list::-webkit-scrollbar-thumb {
  background: #111136;
  width: 5px;
  padding: 5px;
  border-radius: 10px;
}

.menu-itemm:hover {
  background-color: #00a1a2;
  color: white !important;
}

a {
  color: currentColor;
  text-decoration: none;
  white-space: pre-wrap;
}

/* Toggle B */
#check:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
