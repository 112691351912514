.content {
  flex: 0 0 auto;
}
.middlecontent {
  height: 46px;
  margin: 12px;
  max-width: 40%;
}
.select:hover > div > svg> path { 
fill: #00A1A2 !important;

}