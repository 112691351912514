.container {
  display: grid;

  grid-template-areas: 'logo menu other icons';
  grid-gap: 5rem;
  @media (max-width: 1400px) {
   
    grid-gap: 3rem;
  }
  @media (max-width: 1140px) {
    grid-template-areas:
      'logo logo logo'
      'menu menu menu'
      'other other other';

  }
}

.logo {
  grid-area: logo;
}

.menu {
  grid-area: menu;
 
}

.other {
  grid-area: other;
  @media (max-width: 1400px) {
    padding-right: 3rem;
  }
}
.icons {
  grid-area: icons;
}
