.container {
	&:hover {
	  .footer {
		color: white;
		display: none;
	  }
	  .btns {
		display: flex;
	  }
	}
  }
  