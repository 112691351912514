.text {
  font-size: 1.375rem;
}
.btn {
  font-weight: 500;
}
.btnContainer {
  width: 8.375rem;
  flex: 0 0 auto;
}

.btnContent {
  font-size: 0.875rem;
}
