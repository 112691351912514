.open {
  transform: translateX(0);
  @media (max-width: 1023px) {
    transform: translateX(-50%);
  }
}

.close {
  transform: translateX(calc(100% + 40px));
}
