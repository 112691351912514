.container {
  &:hover {
    .footer {
      color: white;

    }
    .btns {
      display: flex;
      background-color: #12a1a2;
      width: 100%;
      height: 100%;
      padding: 0px;
    }

    .video {
      display: none;
    }
  }
}

.containerAdjusted {
  min-height: 400px;
  justify-content: space-between;
}
.containerAdjustedMin {
  min-height: 300px;
  justify-content: space-between;
}
.backgroundImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex: 0.95;
}
