.container {
  &:hover {
    .lab{
      color: white;
    }
    .footer {
      color: white;
    }
    .btns {
      display: flex;
    }

    .video {
      display: none;
    }
  }
}
